import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import NewsSection from "./../components/newsArchive-section";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import { normalizedData } from "@utils/functions";
import BlogInit from "./../container/home/blog/blog.json";

import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../components/preloadPage/preload";

const NewsArchive = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);

    const [Blog, setJsonData] = useState(BlogInit);
    const [preload, setPreload] = useState(true);

    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a5f079a2c34c26dd0b72f1"
        )
            .then(resultData => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch(err => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {preload && <Preload />}
            <SEO title='Blog' pathname='/' />
            <Container>
                {Blog.map((item, i) => {
                    return (
                        <NewsSection
                            key={i}
                            image={item.image}
                            // name='blog1'
                            // designation='image'
                            title={item.title}
                            disc1={item.text}
                            disc2={item.text1}
                            disc3={item.text2}
                        />
                    );
                })}
            </Container>
        </Layout>
    );
};
NewsArchive.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query NewsArchiveQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default NewsArchive;
